/* Definitions */
@font-face {
    font-family: 'Aeonik Pro';
    src: url('./Assets/fonts/Aeonik-Pro.woff2') format('woff2');
}

@font-face {
    font-family: 'MD Nichrome Test';
    src: url('./Assets/fonts/MD-Nichrome-Test.otf') format('opentype');
}

@font-face {
    font-family: 'Inter Regular';
    src: url('./Assets/fonts/Inter-Regular.woff2') format('woff2');
}

* {
    --main-color: #03045E;
    --secondary-color: #F64C72;
    --secondary-color-hover: #F42552;
    --secondary-color-active: #F20D3E;
    --third-color: #F5F0F1;
    --forth-color: #FCFCFC;
    --text-color: white;
    --main-font: 'MD Nichrome Test';
    --secondary-font: 'Aeonik Pro';
    --text-font: 'Inter Regular';
}

/* body */
body {
    margin: auto 0;
    font-family: var(--text-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--main-color);
    color: var(--text-color);
}

@media (min-width: 992px) {
    h1 {
        font-size: 5rem !important;
    }
}

@media (min-width: 375px) {
    h1 {
        font-size: 4.5rem !important;
    }
}

@media (min-width: 375px) {
    h1 {
        font-size: 4rem !important;
    }
}

h1 {
    font-family: var(--main-font);
    font-size: 3rem;
}

@media (min-width: 330px) {
    h2 {
        font-size: 3rem !important;
    }
}

h2 {
    font-family: var(--main-font);
    font-size: 2rem;
}

h3 {
    font-family: var(--secondary-font);
}

.home-icon {
    color: var(--secondary-color);
    height: 40px;
    width: 40px;
}

section {
    min-height: 100vh;
}

.main-font {
    font-family: var(--main-font) !important;
}

.secondary-font {
    font-family: var(--secondary-font) !important;
}

.third-font {
    font-family: var(--text-font) !important;
}

p {
    margin-top: 24px;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

/* Navbar */
.navbar {
    background-color: var(--main-color);
    font-family: var(--secondary-font);
    --bs-navbar-hover-color: var(--secondary-color-hover);
    --bs-navbar-brand-hover-color: var(--secondary-color-hover);
}

.navbar-brand, .nav-link, .nav-btn {
    color: var(--text-color);
    --bs-navbar-hover-color: var(--secondary-color-hover);
}

.nav-btn:hover {
    color: var(--secondary-color-hover);
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: var(--secondary-color) !important;
}

.navbar-toggler {
    color: transparent;
    border: none;
}

.secondary {
    color: var(--main-color) !important;
}

.secondary-bg {
    background-color: var(--third-color);
}

.toggler {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background: transparent;
    color: var(--secondary-color);
}

.close-button {
    color: var(--secondary-color);
    background: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
}

.close-button-rtl {
    color: var(--secondary-color);
    background: transparent;
    border: none;
    position: absolute;
    top: 10px;
    left: 10px;
}

.close {
    width: 30px;
    height: 30px;
}

@media (min-width: 992px) {
    .navbar-brand {
        position: absolute;
    }
    .navbar-end {
        position: absolute;
        right: 12px;
    }
    .navbar-end-rtl {
        position: absolute;
        left: 12px;
    }
    .collapsed-only {
        display: none;
    }
    .close-button {
        display: none;
    }
}

.btn-primary {
    font-family: var(--secondary-font);
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--secondary-color);
    --bs-btn-border-color: var(--secondary-color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--secondary-color-hover);
    --bs-btn-hover-border-color: var(--secondary-color-hover);
    --bs-btn-focus-shadow-rgb: var(--secondary-color-hover);
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--secondary-color-active);
    --bs-btn-active-border-color: var(--secondary-color-active);
    --bs-btn-active-shadow: var(--secondary-color-active);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--secondary-color);
    --bs-btn-disabled-border-color: var(--secondary-color);
}

/* Home */
.typed-font {
    color: transparent;
    background: linear-gradient(to right, #FCCFD8, #FBB6C5, #FA9EB2, #F9869F, #F76E8B, #F64C72);
    background-clip: text;
    -webkit-background-clip: text;
}

.typed-cursor {
    display: none;
    color: transparent;
    background: linear-gradient(#FCCFD8, #FBB6C5, #FA9EB2, #F9869F, #F76E8B, #F64C72);
    background-clip: text;
    -webkit-background-clip: text;
}

.main {
    margin-top: 56px;
}

#emailInput {
    max-width: 300px;
}

.main-btn {
    width: 200px;
}

.btn-outline-light {
    font-family: var(--secondary-font);
}

.payment-method {
    background: white;
    width: 45px;
    height: 45px;
}

.payment-icon {
    width: 75%;
    height: 75%;
}

.particles-js-canvas-el, #language-particles {
    position: absolute;
    top: 0;
    left: 0;
}

canvas {
    z-index: 0;
}

.on-particles {
    z-index: 1;
}

@media (min-width: 992px) {
    .cover {
        height: 122px;
    }
    .cover-2 {
        height: 90px;
    }
}

/* Language Modal */
#LanguageModal {
    background-color: var(--main-color);
    color: var(--text-color);
}

.modal-content {
    --bs-modal-bg: var(--main-color);
}

.language-btn {
    width: 290px;
    transition: all 0.5s ease-in-out;
}

.btn:disabled, .nav-link:disabled {
    cursor: not-allowed;
    pointer-events: all;
}

@media (max-width: 329px) {
    .language-btn {
        width: 210px;
    }
}

.language-btn:hover {
    transform: scale(1.2);
    transition: 0.5s;
}

/* How it works */
#How {
    background-color: var(--third-color);
    color: var(--main-color);
}

.how-img {
    width: 250px;
    height: 250px;
}

.how-p {
    height: 48px;
}

@media (min-width: 992px) {
    #step1::after {
        content: '';
        background-image: url(./Assets/images/upArrow.png);
        background-repeat: no-repeat;
        background-size: 100%;
        width: 70.9px;
        height: 50px;
        position: absolute;
        left: calc((100vw/3)*0.95);
        margin-bottom: calc((250px/3.5));
    }
}

@media (min-width: 992px) {
    #step2::after {
        content: '';
        background-image: url(./Assets/images/downArrow.png);
        background-repeat: no-repeat;
        background-size: 100%;
        width: 70.9px;
        height: 50px;
        position: absolute;
        left: calc((100vw/3)*1.9);
        margin-bottom: calc(250px/2.5);
    }
}

/* Gift Cards */
#GiftCards {
    background-color: var(--third-color);
    color: var(--main-color);
}

/* Checkout */
#Checkout {
    background-color: var(--main-color);
    color: var(--main-color);
}

.checkout-col-1 {
    background-color: var(--third-color);
}

.checkout-col-2 {
    background-color: var(--forth-color);
}

.checkout-container {
    border-radius: 15px;
}

.checkout-left {
    z-index: 1;
}

.greyed-link {
    cursor: pointer;
    color: grey;
}

.greyed-link:hover {
    color: var(--main-color) !important;
}

.checkout-right {
    margin-left: -1.5rem;
    z-index: 0;
}

.checkout-disabled {
    pointer-events:none;
    opacity: 50%;
}

#emailInputGroup {
    background-color: var(--forth-color);
    border-right: none;
}

.validInput {
    border-color: var(--bs-form-valid-border-color);
}

.invalidInput {
    border-color: var(--bs-form-invalid-border-color) !important;
}

#email {
    border-left: none;
    background-color: var(--forth-color);
}

/* Order Complete */
.complete {
    background-color: var(--forth-color);
    color: var(--main-color);
}

.complete-img {
    margin-top: -15%;
}

#redemptionCode {
    border-right: none;
    cursor: pointer;
}

#copy, #copied {
    background-color: white;
    border-left: none;
    cursor: pointer;
}

/* Redeem */
.redeem {
    background-color: var(--forth-color);
    color: var(--main-color);
}

/* Why us */
.why {
    min-height: 45vh;
}

.why-secondary {
    background-color: var(--third-color);
    color: var(--main-color);
}

@media (min-width: 992px) {
    .review2 {
        min-height: 60vh;
    }
}

.why-img {
    max-height: 600px;
    object-fit: contain;
    transition: all 0.5s ease-in-out;
}

.why-img:hover {
    transform: scale(1.1);
    transition: 0.5s;
}

@media (max-width: 991px) {
    .why-img {
        max-height: 500px;
    }
}

.px25{
    padding-left: 12px;
    padding-right: 12px;
}

/* Join */
.btn-outline-dark {
    font-family: var(--secondary-font);
    --bs-btn-color: var(--main-color);
    --bs-btn-hover-color: unset;
    --bs-btn-hover-bg: unset;
    --bs-btn-active-color: unset;
    --bs-btn-active-bg: unset;
}

.topup-method {
    height: 48px;
    width: 48px;
}

@media (max-width: 700px) {
    .topup-text {
        font-size: 2.5rem !important;
    }
}

@media (max-width: 350px) {
    .topup-text {
        font-size: 1.8rem !important;
    }
}

.topup-btn {
    transition: all 0.5s ease-in-out;
    width: 310px;
}

.topup-btn:hover {
    box-shadow: -1px 1px 9px -4px #000;
    transform: scale(1.1);
    transition: 0.5s;
}

@media (max-width: 700px) {
    .topup-btn {
        width: 280px;
    }
}

@media (max-width: 350px) {
    .topup-btn {
        width: 200px;
    }
}

/* Pricing */
.pricing {
    background-color: var(--third-color);
    color: var(--main-color);
}

.right-column {
    background-color: var(--forth-color);
}

td > h5 {
    font-family: var(--secondary-font);
}

/* Contact us */
.contact-container {
    background-color: var(--third-color);
}

.contact-form {
    background-color: white;
    color: var(--main-color);
}

.contact-btn {
    transition: all 0.5s ease-in-out;
    width: 310px;
}

.contact-btn:hover {
    box-shadow: -1px 1px 9px -4px #000;
    transform: scale(1.1);
    transition: 0.5s;
}

@media (max-width: 700px) {
    .contact-btn {
        width: 280px;
    }
}

@media (max-width: 350px) {
    .contact-btn {
        width: 200px;
    }
}

.social-icon {
    width: 50px;
    height: 50px;
    color: var(--main-color);
    transition: all 0.5s ease-in-out;
}

.social-icon:hover {
    transform: scale(1.1);
    transition: 0.5s;
}

/* 404 */
.oops {
    font-size: 6rem !important;
}

@media (min-width: 500px) {
    .oops {
        font-size: 10rem !important;
    }
}

@media (min-width: 370px) {
    .oops {
        font-size: 8rem !important;
    }
}

@media (min-width: 300px) {
    .oops {
        font-size: 6.5rem !important;
    }
}

/* Animations & Transitions */
#GiftCards, #Checkout {
    animation: fadeInAnimation 1s;
}

@keyframes fadeInAnimation {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.payment-method:hover {
    animation: popPaymentMethodOut 0.5s linear forwards;
}

@keyframes popPaymentMethodOut {
    from { transform: scale(1);}
    to { transform: scale(1.2);}
}

.how-img:hover {
    animation: popImageOut 0.5s linear forwards;
}

@keyframes popImageOut {
    from { transform: scale(1);}
    to { transform: scale(1.2);}
}

.scene {
    height: 200px;
    width: 300px;
    perspective: 100px;
    transform: scale(1.1);
    overflow-y: hidden;
    overflow-x: hidden;
}

.carousel {
    height: 100%;
    width: 100%;
    position: absolute;
    transform: rotateY(0deg);
    transform-style: preserve-3d;
    transition: transform 1s;
}

.carousel__cell {
    position: absolute;
    left: 0px;
    top: 0px;
    transition: transform 1s, opacity 1s;
    overflow-y: hidden;
}

.carousel__cell:nth-child(1) { transform: rotateY(0deg)         translateZ(288px);}
.carousel__cell:nth-child(2) { transform: rotateY(51.4286deg)   translateZ(288px);}
.carousel__cell:nth-child(3) { transform: rotateY(102.857deg)   translateZ(288px);}
.carousel__cell:nth-child(4) { transform: rotateY(154.286deg)   translateZ(288px);}
.carousel__cell:nth-child(5) { transform: rotateY(205.714deg)   translateZ(288px);}
.carousel__cell:nth-child(6) { transform: rotateY(257.143deg)   translateZ(288px);}
.carousel__cell:nth-child(7) { transform: rotateY(308.571deg)   translateZ(288px);}

@media (min-width: 420px) {
    .scene {
        transform: scale(1.4);
    }
}

@media (min-width: 800px) {
    .scene {
        height: 270px;
        width: 446.47px;
        position: relative;
        perspective: 1000px;
        transform: scale(1);
        overflow-y: visible;
        overflow-x: visible;
    }
    
    .carousel {
        width: 100%;
        height: 100%;
        position: absolute;
        transform: translateZ(-464px) rotateY(0deg);
        transform-style: preserve-3d;
        transition: transform 1s;
    }
    
    .carousel__cell {
        position: absolute;
        height: 250px;
        width: 426.47px;
        left: 10px;
        top: 10px;
        transition: transform 1s, opacity 1s;
        overflow-y: visible;
    }
    
    .carousel__cell:nth-child(1) { transform: rotateY(0deg)         translateZ(464px);}
    .carousel__cell:nth-child(2) { transform: rotateY(51.4286deg)   translateZ(464px);}
    .carousel__cell:nth-child(3) { transform: rotateY(102.857deg)   translateZ(464px);}
    .carousel__cell:nth-child(4) { transform: rotateY(154.286deg)   translateZ(464px);}
    .carousel__cell:nth-child(5) { transform: rotateY(205.714deg)   translateZ(464px);}
    .carousel__cell:nth-child(6) { transform: rotateY(257.143deg)   translateZ(464px);}
    .carousel__cell:nth-child(7) { transform: rotateY(308.571deg)   translateZ(464px);}
}